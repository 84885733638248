import { API } from '../libs/client';

//get user info
export const getUserInfo = async (data) => {
    const res = await API.get(`/users/detail`, data);
    return res.data;
};

//edit information
export const edit = async (data) => {
    const res = await API.patch(`/users/edit`, data);
    return res.data;
};

// history
export const getListUsers = async (params) => {
    const res = await API.get(`/admin/user/list?${params}`);
    return res.data;
};

// detail user
export const getDetailUser = async (params) => {
    const res = await API.get(`/history/detail?${params}`);
    return res.data;
};
export const getDetailDownload = async (params) => {
    const res = await API.get(`/admin/detail?${params}`);
    return res.data;
};
// block user
export const blockUser = async (data) => {
    const res = await API.post(`/admin/blockuser`, data);
    return res.data;
};

//extend
export const extendUser = async (data) => {
    const res = await API.post(`/admin/addpoint`, data);
    return res.data;
};
export const EditDownload = async (data) => {
    const res = await API.post(`/admin/edit`, data);
    return res.data;
};
export const DeleteDownload = async (data) => {
    const res = await API.post(`/admin/delete`, data);
    return res.data;
};
export const startWebPage = async (typeId) => {
    const res = await API.post(`/admin/startWebPage?pageDownload=${typeId}`);
    return res.data;
};

// add page
export const addPage = async (data) => {
    const res = await API.post(`/admin/addpage`, data);
    return res.data;
};
