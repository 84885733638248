import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// utilities routing
const Homepage = Loadable(lazy(() => import('views/utilities/Homepage')));
const ManageUsers = Loadable(lazy(() => import('views/utilities/ManageUsers')));
const Information = Loadable(lazy(() => import('views/utilities/Information')));
const ChangePass = Loadable(lazy(() => import('views/utilities/ChangePass')));
const DetailUser = Loadable(lazy(() => import('views/utilities/DetailUser')));
const DetailDownload = Loadable(lazy(() => import('views/utilities/DetailDownload')));
// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// check login
import { ProtectedRoute } from './ProtectedRoute';
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '',
            element: (
                <ProtectedRoute>
                    <Homepage />
                </ProtectedRoute>
            )
        },
        {
            path: 'usersList',
            element: (
                <ProtectedRoute>
                    <ManageUsers />
                </ProtectedRoute>
            )
        },
        {
            path: 'userDetail/:id',
            element: (
                <ProtectedRoute>
                    <DetailUser />
                </ProtectedRoute>
            )
        },
        {
            path: 'detailDownload/:id',
            element: (
                <ProtectedRoute>
                    <DetailDownload />
                </ProtectedRoute>
            )
        },
        {
            path: 'information',
            element: (
                <ProtectedRoute>
                    <Information />
                </ProtectedRoute>
            )
        },
        {
            path: 'changePass',
            element: (
                <ProtectedRoute>
                    <ChangePass />
                </ProtectedRoute>
            )
        },
        {
            path: 'sample-page',
            element: <SamplePage />
        }
    ]
};

export default MainRoutes;
