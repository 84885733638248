// assets
import { IconHistory, IconInfoCircle, IconKey, IconHome, IconList } from '@tabler/icons';

// constant
const icons = {
    IconHome,
    IconList,
    IconHistory,
    IconInfoCircle,
    IconKey
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const utilities = {
    id: 'utilities',
    type: 'group',
    children: [
        {
            id: 'homepage',
            title: 'Trang chủ',
            type: 'item',
            url: '/',
            icon: icons.IconHome,
            breadcrumbs: false
        },
        {
            id: 'list-users',
            title: 'Quản lý người dùng',
            type: 'item',
            url: '/usersList',
            icon: icons.IconList,
            breadcrumbs: false
        },
        {
            id: 'information',
            title: 'Thông tin',
            type: 'item',
            url: '/information',
            icon: icons.IconInfoCircle,
            breadcrumbs: false
        },
        {
            id: 'changePass',
            title: 'Đổi mật khẩu',
            type: 'item',
            url: '/changePass',
            icon: icons.IconKey,
            breadcrumbs: false
        }
    ]
};

export default utilities;
