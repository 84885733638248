import { createContext, useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from './useLocalStorage';
import { login, logout } from '../auth/api/auth';
import { getUserInfo } from 'auth/api/admin';
import swal from 'sweetalert';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useLocalStorage('user', null);
    const navigate = useNavigate();
    // call this function when you want to authenticate the user
    const loginAuth = async (data) => {
        login(data)
            .then((res) => {
                if (res.success) {
                    setUser(res.accessToken);
                    localStorage.setItem('accessToken', res.accessToken);
                    getUserInfo().then((response) => {
                        localStorage.setItem('userInfo', JSON.stringify(response.data));
                    });
                    navigate('/');
                } else {
                    swal(res.message, '', 'error');
                }
            })
            .catch((err) => {
                if (err.response?.status === 400 || err.response?.status === 404) {
                    swal(err.response?.data.message, '', 'error');
                }
            });
    };

    // call this function to sign out logged in user
    const logoutAuth = () => {
        logout()
            .then((res) => {
                if (res.success) {
                    setUser(null);
                    localStorage.removeItem('accessToken');
                    navigate('/auth-login');
                }
            })
            .catch((err) => {
                console.error(err.response.status);
                if (err.response.status == 403) {
                    setUser(null);
                    localStorage.removeItem('accessToken');
                    navigate('/auth-login');
                }
            });
    };
    const value = useMemo(
        () => ({
            user,
            loginAuth,
            logoutAuth
        }),
        [user]
    );
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    return useContext(AuthContext);
};
